<template>
  <div id="supplier-detail" class="fx-main" v-if="supplier">
    <div class="sup-block wd-100">
      <h3 class="txt-align-center">{{ supplier.company_name }}</h3>
    </div>
    <ProductList :search="{ suppliers: supplier_id }" />
  </div>
</template>

<script>
import User from "@/services/user.js";
import ProductList from "@/components/storekeeper/ProductList";

export default {
  name: "SupplierDetail",
  template: "#supplier-detail",
  props: {
    supplier_id: String,
    supplier_name: String
  },
  data: function() {
    return {
      supplier: null
    };
  },
  created() {
    User.get(this.supplier_id).then(supplier => {
      this.supplier = supplier;
    });
  },
  components: {
    ProductList: ProductList
  }
};
</script>